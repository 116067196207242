import React, { useEffect, useState } from "react";
import PrimaryButton from "../components/Buttons/PrimaryButton";
import { useNavigate } from "react-router-dom";
import SecountryBtn from "../components/Buttons/SecountryBtn";
import OtpInput from "react-otp-input";
import EmailVerificationModal from "../components/Modals/EmailVerificationModal";
import animationSuccess from "../assets/lottie/animation-success.json";
import animationFail from "../assets/lottie/animation-fail.json";
import axios from "axios";
import { async } from "q";
import { apiURL } from "../helpers/URL";
import toast, { Toaster } from "react-hot-toast";
import { obscureEmail } from "../utils/obscureEmail";
import { emailVerificationCountdown } from "../constant/Countdown";

const EmailVerification = () => {
  const navigate = useNavigate();

  const [otp, setOtp] = useState("");
  const [countdown, setCountdown] = useState(emailVerificationCountdown);
  const [isVerificationModal, setVerificationModal] = useState(false);
  const [verifyResponce, setVerifiyResponce] = useState();

  const decodedEmail = localStorage.getItem("reg-site");
  const userEmail = atob(decodedEmail);

  const backToHome = () => {
    navigate("/");
  };
  const targetPage = "/";
  const backToRegister = () => {
    navigate("/registration");
    localStorage.clear();
  };

  const verifiyModalToggle = () => {
    setVerificationModal(true);
  };

  const verifiySuccess = () => {
    verifiyModalToggle();
    setTimeout(() => {
      setVerificationModal(false);
      navigate("/game-spin");
    }, 4000);
  };

  const verifiyError = () => {
    verifiyModalToggle();
    setTimeout(() => {
      setVerificationModal(false);
    }, 4000);
  };
  const closeModal = () => {
    setVerificationModal(false);
  };
  const emailVerify = async () => {
    try {
      const data = {};
      data["otp"] = parseInt(otp);
      data["email"] = userEmail;
      setOtp("");
      setCountdown(emailVerificationCountdown);
      const response = await axios.post(
        `${apiURL}/adminpanel/subscription/verification`,
        data
      );
      setVerifiyResponce(response?.data?.data);

      if (response?.data?.data?.verified === true) {
        verifiySuccess();
      } else {
        verifiyError();
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  //Token Resend
  const tokenResend = async () => {
    try {
      const data = {};
      data["email"] = userEmail;
      const response = await axios.post(
        `${apiURL}/adminpanel/subscription/resend-code`,
        data
      );
      toast.success(response?.data?.message);
      if (response?.data?.status?.toLowerCase() === "success") {
        setOtp("");
        setCountdown(emailVerificationCountdown);
      } else {
      }
    } catch (error) { }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCountdown((prevCountdown) => prevCountdown - 1);
    }, 1000);

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, []);

  useEffect(() => {
    // Redirect when countdown reaches 0
    if (countdown === 0) {
      window.location.href = targetPage;
    }
  }, [countdown, targetPage]);

  return (
    <>
      <div className="page-container">
        <div className="register-screen email-verfiy">
          <form autoComplete="off">
            <h5 className="form-title">Email verification</h5>
            <div className="check-email border-0">
              <p>
                We've sent you an OTP to {obscureEmail(userEmail)}. Please check your inbox (or junk/spam folder) for the verification code.
                Enter the code below within {emailVerificationCountdown} seconds to proceed.

              </p>
            </div>
            <div className="d-flex verfiy-token">
              <div className="items w-100 m-auto">
                <OtpInput
                  value={otp}
                  onChange={setOtp}
                  numInputs={4}
                  inputType="tel"
                  renderInput={(props) => <input {...props} />}
                />
              </div>
            </div>
            <div className="resent-token text-center">
              <p>
                Didn't receive the Verification Code?{" "}
                <span onClick={tokenResend}>Request again</span>
              </p>
              <p>
                Or,{" "}
                <span
                  onClick={() => {
                    backToRegister();
                  }}
                >
                  Try with another email
                </span>
              </p>
            </div>
            <div className="form-footer gap-4">
              <PrimaryButton
                btnName={"Verify"}
                ClassName=""
                btnType={"button"}
                onClick={emailVerify}
                disabled={otp?.length === 0 ? true : false}
              />
              <SecountryBtn
                btnName={"Back to Home"}
                ClassName=""
                btnType={"button"}
                onClick={backToHome}
              />
            </div>
          </form>
          <div className="home-directed">
            <p className="text-center">
              You will be directed to the home screen in <b>00 : {countdown}</b>
            </p>
          </div>
        </div>
      </div>
      <EmailVerificationModal
        toggle={verifiyModalToggle}
        lottiefiles={
          verifyResponce?.verified === true ? animationSuccess : animationFail
        }
        isOpen={isVerificationModal}
        modalTitle={
          verifyResponce?.verified === true
            ? "Successfully verified!"
            : "Wrong verification code"
        }
        description={
          verifyResponce?.verified === true
            ? "Your email has been successfully verified. You may now proceed to the lucky draw."
            : `${`You've entered an invalid verification code. Please refer to your email and try again.`}`
        }
        buttonName={verifyResponce?.verified === true ? "" : "Try Again"}
        PrimaryAction={verifyResponce?.verified === true ? "" : closeModal}
      />
      <Toaster
        position="top-right"
        reverseOrder={false}
        toastOptions={{
          success: {
            iconTheme: {
              primary: "#fff",
              secondary: "#34c38f",
            },
            style: {
              background: "#34c38f",
              color: "#fff",
              fontSize: "18px",
            },
          },
        }}
      />
    </>
  );
};

export default EmailVerification;
